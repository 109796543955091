import { createAction } from '@reduxjs/toolkit';
import { StateAppSettingsType } from '../reducers/types';

export const setUserIntl = createAction(
  'APP/SETTINGS_INTL_SET',
  (data: Pick<StateAppSettingsType, 'locale' | 'timeZone'>) => ({
    payload: {
      ...data,
      isFetchingLocale: true,
    },
  }),
);

export const setWithDarkMode = createAction(
  'APP/SETTINGS_DARK_MODE_SET',
  (withDarkMode: StateAppSettingsType['withDarkMode']) => ({
    payload: {
      withDarkMode,
    },
  }),
);

export const languageChanged = createAction('APP/SETTINGS_INTL_CHANGED', (locale: StateAppSettingsType['locale']) => ({
  payload: {
    locale,
  },
}));

export const cookiesChanged = createAction(
  'APP/SETTINGS_COOKIES_CHANGED',
  (data: { necessary: boolean; preferences: boolean; statistics: boolean; marketing: boolean }) => ({
    payload: { data },
  }),
);

export const setIsEmployerHost = createAction('APP/SET_IS_EMPLOYER_HOST', (isEmployerHost: boolean) => ({
  payload: {
    isEmployerHost,
  },
}));
