import { createReducer } from '@reduxjs/toolkit';
import { cookiesChanged, languageChanged, setIsEmployerHost, setUserIntl, setWithDarkMode } from '../actions';
import { StateAppSettingsType } from './types';

export const initial: StateAppSettingsType = {
  isFetchingLocale: false,
  withDarkMode: false,
  isEmployerHost: false,
  isCookiesAccepted: {
    necessary: false,
    preferences: false,
    statistics: false,
    marketing: false,
  },
};

const reducer = createReducer<StateAppSettingsType>(initial, (builder) =>
  builder
    .addCase(setUserIntl, (state, { payload }) => ({ ...state, ...payload }))
    .addCase(setWithDarkMode, (state, { payload }) => {
      state.withDarkMode = payload.withDarkMode;
    })
    .addCase(languageChanged, (state, { payload }) => {
      state.locale = payload.locale;
    })
    .addCase(cookiesChanged, (state, { payload }) => {
      state.isCookiesAccepted = payload.data;
    })
    .addCase(setIsEmployerHost, (state, { payload }) => {
      state.isEmployerHost = payload.isEmployerHost;
    }),
);

export default reducer;
