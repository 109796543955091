import { createUseStyles } from '@pe-libs/react-components/build/theme';

const useStyles = createUseStyles(
  'expert-directory-main-menu',
  ({ palette: { primary, grey, common }, breakpoints: { up, between }, typography }) => ({
    mainMenu: {
      '& .MuiDrawer-paper': {
        width: '18.875rem',
        background: grey[800],
        color: common.white,
      },
    },
    logo: { marginTop: '14px', marginLeft: '12px' },
    iconBox: {
      height: '25px',
      fontSize: '1.5rem',
      marginRight: '1rem',
    },
    languageContainer: {
      padding: '0 1rem',
    },
    mainMenuContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      [up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    xContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '3rem',
    },
    xIcon: {
      color: 'rgba(255,255,255,0.6)',
      margin: '0.2rem 0.5rem',

      '&.MuiIconButton-root': {
        padding: '0.5rem',
        paddingTop: '14px',
        '&:hover': {
          color: common.white,
          background: 'none',
        },
      },
    },
    list: {
      overflow: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      [up('md')]: {
        flexDirection: 'row',
      },
    },
    listItem: {
      color: common.white,
      padding: '1rem 1.5rem',
      '&:hover': {
        backgroundColor: primary.main,
      },

      '&.Mui-selected': {
        backgroundColor: primary.main,
        '&:hover': {
          backgroundColor: primary.dark,
          [up('md')]: {
            backgroundColor: common.white,
          },
        },
      },

      [up('md')]: {
        padding: 0,
        color: primary.main,

        '&:hover': {
          borderBottom: `1px solid ${primary.main}`,
          backgroundColor: common.white,
        },

        '&.Mui-selected': {
          borderBottom: `1px solid ${primary.main}`,
          backgroundColor: common.white,
        },
      },
    },
    listItemText: {
      margin: '0',
      '& .MuiTypography-body1': {
        fontSize: '1rem',
        fontWeight: typography.fontWeightSemiBold,
        [up('md')]: {
          fontWeight: typography.fontWeightRegular,
        },
      },
    },
    actionsContainer: {
      padding: '1.5rem 1.5rem 1rem',
      textAlign: 'center',
      [up('md')]: {
        padding: 0,
      },
      '& .MuiButtonBase-root': {
        '& .MuiButton-label': {
          whiteSpace: 'nowrap',
        },
      },
    },
    button: {
      width: '13.78rem',
      marginBottom: '0.5rem',
      [up('md')]: {
        marginBottom: '0',
      },
    },
    overrideWhiteColorForButton: {
      '&.MuiButtonBase-root.MuiButton-outlined': {
        borderColor: common.white,

        '&:hover': {
          background: common.white,
        },
      },
    },
    link: {
      [between('md', 990)]: {
        marginRight: '1rem',
      },
      [up(990)]: {
        marginRight: '1.5rem',
      },
      '&.MuiLink-underlineHover:hover': {
        textDecoration: 'none',
      },
    },
  }),
);

export default useStyles;
