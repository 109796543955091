import { defineMessages } from 'react-intl';

const messages = defineMessages({
  languageGerman: { id: 'modules-auth-contentLayout-languageSwitch-languageGerman' },
  languageEnglishUs: { id: 'modules-auth-contentLayout-languageSwitch-languageEnglishUs' },
  languageEnglishGb: { id: 'modules-auth-contentLayout-languageSwitch-languageEnglishGb' },
  languageSpanish: { id: 'modules-auth-contentLayout-languageSwitch-languageSpanish' },
  languageFrench: { id: 'modules-auth-contentLayout-languageSwitch-languageFrench' },
  languageItalian: { id: 'modules-auth-contentLayout-languageSwitch-languageItalian' },
  languagePortuguese: { id: 'modules-auth-contentLayout-languageSwitch-languagePortuguese' },
  languageDutch: { id: 'modules-auth-contentLayout-languageSwitch-languageDutch' },
  languagePolish: { id: 'modules-auth-contentLayout-languageSwitch-languagePolish' },
});

export default messages;
