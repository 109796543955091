import { createUseStyles, Theme } from '@pe-libs/react-components/build/theme';
import imgCooperation from '#assets/images/cooperation.jpg';
import imgCooperationX2 from '#assets/images/cooperation@2x.jpg';
import imgFun from '#assets/images/fun.jpg';
import imgFunX2 from '#assets/images/fun@2x.jpg';
import imgGroup from '#assets/images/group.jpg';
import imgGroupX2 from '#assets/images/group@2x.jpg';
import imgPresentation from '#assets/images/presentation.jpg';
import imgPresentationX2 from '#assets/images/presentation@2x.jpg';
import imgStairs from '#assets/images/stairs.jpg';
import imgStairsX2 from '#assets/images/stairs@2x.jpg';
import starReview from '#assets/images/star-review.png';
import imgTalk from '#assets/images/talk.jpg';
import imgTalkX2 from '#assets/images/talk@2x.jpg';
import thumb from '#assets/images/thumb.png';

const createBgImage = (img: string, imgX2: string, theme: Theme) => ({
  [theme.breakpoints.up('sm')]: {
    backgroundImage: `url(${img})`,
  },

  [theme.breakpoints.up('xl')]: {
    backgroundImage: `url(${imgX2})`,
  },

  '@media(min-resolution: 192dpi), @media(-webkit-min-device-pixel-ratio: 2)': {
    backgroundImage: `url(${imgX2})`,
  },
});

const useStyles = createUseStyles('pe-content-layout', (theme) => ({
  bgCooperation: createBgImage(imgCooperation, imgCooperationX2, theme),
  bgFun: createBgImage(imgFun, imgFunX2, theme),
  bgPresentation: createBgImage(imgPresentation, imgPresentationX2, theme),
  bgStairs: createBgImage(imgStairs, imgStairsX2, theme),
  bgTalk: createBgImage(imgTalk, imgTalkX2, theme),
  bgGroup: createBgImage(imgGroup, imgGroupX2, theme),
  bgAffiliate: {
    background: 'linear-gradient(304deg, #35A9A6 6.66%, #0DB1CD 93.32%);',
  },
  affiliateLogoContent: {},

  affiliateIcons: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '16px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '153px',
    },
  },
  affiliateLeftIcon: {
    width: '108px',
    height: '94px',
    backgroundImage: `url(${starReview})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      width: '160px',
      height: '108px',
      marginRight: '-30px',
    },
  },
  affiliateRightIcon: {
    width: '108px',
    height: '85px',
    backgroundImage: `url(${thumb})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      width: '160px',
      height: '123px',
      marginLeft: '-30px',
    },
  },

  mainContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      minHeight: '100vh',
    },
  },

  sideContainer: {
    position: 'relative',
    padding: '1rem',
    textAlign: 'center',
    minHeight: '100vh',
    background: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.common.white,

    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      height: '100%',
      width: '25.625rem',
    },

    [theme.breakpoints.up('md')]: {
      width: '27.5rem',
      padding: '1.5rem',
    },

    [theme.breakpoints.up('lg')]: {
      width: '31.25rem',
    },
  },

  icon: {
    height: '3.429em',
    padding: 0,
    display: 'flex',
    position: 'relative',
    '& button, & .MuiListItem-button': {
      transition: 'all 0.3s',
      '& > svg': {
        transition: 'color 0.3s',
      },
    },
  },

  iconLangSwitch: {
    position: 'relative',
    zIndex: 2,
  },

  langSwitchContainer: {
    position: 'absolute',
    right: '1rem',
    top: '4px',
    [theme.breakpoints.up('lg')]: {
      right: '24px',
      top: '13px',
    },
  },

  langSwitch: {
    '&:hover': {
      background: 'none',
    },
  },

  marketingSloganContainer: {
    display: 'flex',
    flex: 1,
  },

  affiliateMask: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '194px',
  },

  affiliateLogo: {
    [theme.breakpoints.up('sm')]: {
      width: '294px',
      height: '288px',
      marginTop: '0',
    },
    marginTop: '24px',
    width: '120px',
    height: '117px',
    borderRadius: '50%',
    backgroundImage: ({ avatarUrl }: { avatarUrl: string | undefined }) => `url(${avatarUrl})`,
    backgroundColor: theme.palette.common.white,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    boxShadow: '-4px 14px 30px 0px rgba(0, 0, 0, 0.20)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  affiliateMobileLayout: {
    marginLeft: '-16px',
    marginRight: '-16px',
    padding: '27px 27px 27px 34px',
    color: theme.palette.common.white,
    marginBottom: '24px',
    height: '291px',
  },

  gradientMask: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    background:
      'linear-gradient(to bottom,  rgba(13, 177, 205, 0) 36%,rgba(53, 169, 166, 0.8) 74%,rgba(53, 169, 166, 0.6) 83%)',
  },

  sideContent: {
    width: '100%',
    margin: '0 auto',
  },

  logo: {
    marginTop: '65px',
    marginBottom: '2em',
    height: '4.688rem',

    [theme.breakpoints.up('sm')]: {
      marginTop: '97px',
      marginBottom: '4.57143em',
    },
  },

  registerTitle: {
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: '0.4em',
    letterSpacing: '0.011em',
  },

  registerDesc: {
    marginBottom: '1.813em',
    letterSpacing: '0.011em',
  },

  imageContainer: {
    display: 'flex',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
  },

  sloganContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '1em',
    paddingRight: '1em',
    color: theme.palette.common.white,
  },

  sloganTextShadow: {
    textShadow: '1px 1px 5px rgba(0, 0, 0, 0.35)',
  },

  sloganTitle: {
    maxWidth: '25.5em',
    marginBottom: '0.317em',
    fontWeight: theme.typography.fontWeightSemiBold,
    composes: '$sloganTextShadow',
  },

  sloganDesc: {
    maxWidth: '25.5em',
    marginBottom: '20%',
    fontWeight: theme.typography.fontWeightSemiBold,
    composes: '$sloganTextShadow',

    '@media (min-height: 1000px)': {
      marginBottom: '25%',
    },
  },
  flagButton: {
    width: '28px !important',
    height: '20px !important',
    borderRadius: '4px !important',
  },
  flag: {
    width: '22.4px !important',
    height: '16px !important',
    borderRadius: '4px !important',
  },
  langMenu: {
    [theme.breakpoints.up('md')]: {
      marginTop: '30px',
    },
    '& ul': {
      '&.MuiList-root': {
        padding: '7px',
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: '8px',
        background: theme.palette.common.white,
        boxShadow: '0px 6px 10px -1px rgba(0, 0, 0, 0.10)',
      },
      '& li': {
        padding: '9px 35px 9px 23px',
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: '4px',
        '&:nth-child(1)': {
          padding: 0,
          height: 0,
          border: 'none',
          '&.MuiMenuItem-root': {
            minHeight: 0,
          },
        },
        '&:nth-child(2)': {
          borderColor: theme.palette.common.white,
        },
        '&:hover': {
          color: theme.palette.grey[700],
          background: theme.palette.grey[50],
        },
        '&.Mui-selected': {
          color: theme.palette.grey[700],
          background: theme.palette.grey[100],
          '&:hover': {
            color: theme.palette.grey[700],
            background: theme.palette.grey[100],
          },
        },
      },
    },
  },
}));

export default useStyles;
